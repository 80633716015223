body {
	margin: 0;
	font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
	--black: #202124;
	--grey1: #536471;
	--grey2: #53647180;
	--grey3: #53647126;
	--grey4: #f3f4f5;
	--blue1: #2e31db;
	--blue2: #4548ff;
	--green1: #047f2c;
	--green2: #0a9c3a;
	--red1: #b61b1b;
	--red2: #d82b2b;
	--border: 0 0% 91%;
	--radius: 12px;
}
